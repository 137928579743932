import React from 'react'

import {
  TextInput,
  ImageInput,
  maxLength,
  SimpleForm,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  BooleanInput,
  SelectInput,
} from 'react-admin'

import Grid from '@material-ui/core/Grid'
import CustomImageField from '../../components/CustomImageField'
import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import { validateRequired } from '../../utils/validate'
import { BOOK_STATUSES, COURSE_STATUSES } from '../../components/constants'
import Typography from '@material-ui/core/Typography'

const B2BOrganizationForm = props => {
  const validateMaxLength = maxLength(
    200,
    'Do not enter more than 200 characters',
  )

  const statusOptions = [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'inactive',
      name: 'Inactive',
    },
  ]

  return (
    <SimpleForm
      {...props}
      toolbar={<BookToolbar />}
      redirect="list"
      variant="standard"
      margin="normal">
      <GridContainer>
        <Grid item xs={12}>
          <Typography variant="h5">Thông tin công ty</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            source="name"
            label="Tên công ty"
            fullWidth
            variant="standard"
            validate={[validateRequired('Tên công ty'), validateMaxLength]}
          />
        </Grid>

        <Grid item xs={12}>
          <ArrayInput label="Admin Email" source="adminEmails" fullWidth>
            <SimpleFormIterator>
              <TextInput label="Email" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ImageInput
            source="logo"
            label="Logo"
            accept="image/*"
            multiple={false}
            validate={validateRequired('Logo')}>
            <CustomImageField source="src" title="Logo" />
          </ImageInput>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Thông tin hợp đồng</Typography>
        </Grid>

        <Grid item xs={3}>
          <NumberInput
            source="totalSeats"
            label="Số Slot"
            fullWidth
            variant="standard"
            validate={[validateRequired('Số Slot')]}
          />
        </Grid>

        <Grid item xs={3}>
          <SelectInput
            label="Status"
            source="status"
            choices={statusOptions}
            defaultValue="active"
            alwaysOn
            fullWidth
            allowEmpty={false}
          />
        </Grid>

        <Grid item xs={3}>
          <DateInput
            source="startDate"
            label="Ngày bắt đầu"
            fullWidth
            validate={[validateRequired('Ngày bắt đầu')]}
          />
        </Grid>

        <Grid item xs={3}>
          <DateInput
            source="endDate"
            label="Ngày kết thúc"
            fullWidth
            validate={[validateRequired('Ngày kết thúc')]}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Danh sách nội dung</Typography>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput label="Books" source="books" fullWidth>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <ReferenceInput
                      label="Books"
                      source={getSource('bookId')}
                      reference="books"
                      rows={6}
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                        status: BOOK_STATUSES.PUBLISHED,
                      })}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>

        <Grid item xs={12}>
          <ArrayInput label="PodCourse" source="courses" fullWidth>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <ReferenceInput
                      label="Course"
                      source={getSource('courseId')}
                      reference="courses"
                      rows={6}
                      filterToQuery={searchText => ({
                        title: { $iLike: `%${searchText}%` },
                        status: COURSE_STATUSES.PUBLISHED,
                      })}>
                      <AutocompleteInput
                        optionText="title"
                        options={{
                          fullWidth: true,
                        }}
                        variant="standard"
                      />
                    </ReferenceInput>
                  )
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Nội dung khác</Typography>
        </Grid>
        <Grid item xs={6}>
          <BooleanInput
            source="includeEnglishBook"
            lable="English"
            title="English"
          />
        </Grid>
        <Grid item xs={6}>
          <BooleanInput
            source="includeMeditation"
            lable="Meditation"
            title="Meditation"
          />
        </Grid>
      </GridContainer>
    </SimpleForm>
  )
}

export default B2BOrganizationForm
