import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  TextInput,
  NumberField,
  BooleanField,
} from 'react-admin'

import FilterComponent from '../../components/Filter'

const ArticleFilter = props => (
  <FilterComponent {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </FilterComponent>
)

const B2BOrganizationList = props => (
  <List
    {...props}
    filters={<ArticleFilter />}
    bulkActionButtons={false}
    sort={{ field: 'updatedAt', order: 'DESC' }}>
    <Datagrid>
      <NumberField label="ID" source="id" />
      <TextField label="Tên công ty" source="name" />
      <NumberField label="Số slot" source="totalSeats" />
      <DateField label="Ngày bắt đầu" source="startDate" />
      <DateField label="Ngày kết thúc" source="endDate" />
      <NumberField label="Số Sách nói" source="summary.totalBooks" />
      <NumberField label="Số PodCourse" source="summary.totalCourses" />
      <BooleanField label="English" source="includeEnglishBook" />
      <BooleanField label="Thiền" source="includeMeditation" />
      <TextField label="Status" source="status" />
      <EditButton />
      {/*<DeleteButton undoable={false} />*/}
    </Datagrid>
  </List>
)

export default B2BOrganizationList
