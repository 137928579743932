/* eslint-disable no-unused-vars */
import uuidv1 from 'uuid/v1'
import axios from 'axios'
import feathersClient from './feathersClient'

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject

    reader.readAsDataURL(file.rawFile)
  })

const uploadFile = ({ file, folder, storageKey, key, customFileName }) => {
  const fileName =
    key ||
    [storageKey, folder, customFileName || Date.now().toString()]
      .filter(s => s)
      .join('/')

  return upload(file, fileName)
}

const uploadAvatar = ({ file }) => {
  const fileName = `avatar/author/${Date.now().toString()}`

  return upload(file, fileName)
}

const uploadPublisherAvatar = ({ file }) => {
  const fileName = `avatar/publisher/${Date.now().toString()}`

  return upload(file, fileName)
}

const uploadCoachAvatar = ({ file }) => {
  const fileName = `avatar/coach/${Date.now().toString()}`

  return upload(file, fileName)
}

const uploadPicture = ({ file }) =>
  upload(file, `avatar/affiliate/${Date.now().toString()}`)

export const uploadChapter = file => {
  const fileName = `temp/chapters/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName)
}

export const uploadAttachment = file => {
  const fileName = `temp/attachments/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName)
}

export const uploadSubtitle = file => {
  const fileName = `temp/subtitles/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName)
}

export const uploadGuidedMeditationSession = file => {
  const fileName = `temp/guided-meditation-sessions/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName)
}

const uploadSlideshowImage = ({ file }) => {
  const fileName = `slideshows/${Date.now().toString()}`

  return upload(file, fileName)
}

export const uploadEpisode = (file, onUploadProgress) => {
  const fileName = `temp/episodes/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName, false, onUploadProgress)
}

export const uploadFileSample = (file, onUploadProgress) => {
  const fileName = `temp/file-samples/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName, false, onUploadProgress)
}

export const uploadTeaser = (file, onUploadProgress) => {
  const fileName = `temp/teasers/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName, false, onUploadProgress)
}

export const uploadIntroVideo = (file, onUploadProgress) => {
  const fileName = `temp/intro-videos/${Date.now().toString()}`

  return upload({ rawFile: file }, fileName, false, onUploadProgress)
}

export const removeFile = fileUrl => {
  return feathersClient
    .get()
    .service('remove-files')
    .create({ key: fileUrl })
}

const getFileDuration = file =>
  new Promise(resolve => {
    const audio = document.createElement('audio')
    const reader = new FileReader()

    reader.onload = function(e) {
      audio.src = e.target.result
      audio.addEventListener(
        'loadedmetadata',
        function() {
          resolve(audio.duration)
        },
        false,
      )
    }

    reader.readAsDataURL(file)
  })

const getVideoFileDuration = file =>
  new Promise(resolve => {
    const video = document.createElement('video')
    video.addEventListener(
      'loadedmetadata',
      function() {
        resolve(video.duration)
      },
      false,
    )

    video.src = URL.createObjectURL(file)
  })

const getDuration = (file, contentType) => {
  if (contentType) {
    if (contentType.includes('audio')) {
      return getFileDuration(file.rawFile)
    }
    if (contentType.includes('video')) {
      return getVideoFileDuration(file.rawFile)
    }
  }
  return Promise.resolve(0)
}

const upload = async (file, fileName, firebase = false, onUploadProgress) => {
  if (!file || !file.rawFile) return false
  const fileExtension = file.rawFile.name.split('.').pop()
  fileName = `${fileName}.${fileExtension}`

  const { signedUrl, contentType } = await feathersClient
    .get()
    .service('upload-files')
    .create({ fileName, fileExtension, firebase })

  const [duration] = await Promise.all([
    getDuration(file, contentType),
    axios.put(signedUrl, file.rawFile, {
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress,
    }),
  ])

  return { url: fileName, duration }
}

const addUploadFeature = requestHandler => async (type, resource, params) => {
  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'books') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    const {
      coverImageUrl: coverImageFile,
      fileSampleUrl: fileSampleFile,
      chapters: chapterFiles = [],
      attachments: attachmentFiles = [],
    } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'books/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const [coverImageUrl, fileSampleUrl] = await Promise.all([
      uploadFile({ file: coverImageFile, folder: 'coverImage', storageKey }),
      uploadFile({ file: fileSampleFile, folder: 'sample', storageKey }),
    ])

    const chapters = [],
      attachments = []

    for (const chapterKey in chapterFiles) {
      const chapter = chapterFiles[chapterKey]
      if (typeof chapter.url === 'object') {
        if (chapter.url.rawFile && !chapter.url.rawFile.url) {
          throw `Chapter ${chapter.order} is uploading ...`
        } else {
          chapters.push({
            ...chapter,
            url: chapter.url.rawFile.url.url,
            duration: chapter.url.rawFile.url.duration,
          })
        }
      } else {
        chapters.push(chapter)
      }
    }

    for (const attachmentKey in attachmentFiles) {
      const attachment = attachmentFiles[attachmentKey]
      if (typeof attachment.url === 'object') {
        if (attachment.url.rawFile && !attachment.url.rawFile.url) {
          throw 'Attachments is uploading ...'
        } else {
          attachments.push({
            ...attachment,
            url: attachment.url.rawFile.url.url,
          })
        }
      } else {
        attachments.push(attachment)
      }
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(chapterFiles.length && { chapters }),
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(fileSampleUrl && {
          fileSampleUrl: fileSampleUrl.url,
          fileSampleDuration: fileSampleUrl.duration,
        }),
        ...(attachmentFiles.length && { attachments }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'english-books'
  ) {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    const {
      coverImageUrl: coverImageFile,
      fileSampleUrl: fileSampleFile,
      fileSampleSubtitleUrl: fileSampleSubtitleFile,
      chapters: chapterFiles = [],
      attachments: attachmentFiles = [],
    } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'english-books/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const [
      coverImageUrl,
      fileSampleUrl,
      fileSampleSubtitleUrl,
    ] = await Promise.all([
      uploadFile({ file: coverImageFile, folder: 'coverImage', storageKey }),
      uploadFile({ file: fileSampleFile, folder: 'sample', storageKey }),
      uploadFile({
        file: fileSampleSubtitleFile,
        folder: 'sample',
        storageKey,
      }),
    ])

    const chapters = [],
      attachments = []

    for (const chapterKey in chapterFiles) {
      let chapter = { ...chapterFiles[chapterKey] }
      if (typeof chapter.url === 'object') {
        if (chapter.url.rawFile && !chapter.url.rawFile.url) {
          throw `Chapter ${chapter.order} is uploading ...`
        } else {
          Object.assign(chapter, {
            url: chapter.url.rawFile.url.url,
            duration: chapter.url.rawFile.url.duration,
          })
        }
      }

      if (typeof chapter.subtitleUrl === 'object') {
        if (
          chapter.subtitleUrl.rawFile &&
          !chapter.subtitleUrl.rawFile.subtitleUrl
        ) {
          throw `Subtitle ${chapter.order} is uploading ...`
        } else {
          Object.assign(chapter, {
            subtitleUrl: chapter.subtitleUrl.rawFile.subtitleUrl.url,
          })
        }
      }

      chapters.push(chapter)
    }

    for (const attachmentKey in attachmentFiles) {
      const attachment = attachmentFiles[attachmentKey]
      if (typeof attachment.url === 'object') {
        if (attachment.url.rawFile && !attachment.url.rawFile.url) {
          throw 'Attachments is uploading ...'
        } else {
          attachments.push({
            ...attachment,
            url: attachment.url.rawFile.url.url,
          })
        }
      } else {
        attachments.push(attachment)
      }
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(chapterFiles.length && { chapters }),
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(fileSampleUrl && {
          fileSampleUrl: fileSampleUrl.url,
          fileSampleDuration: fileSampleUrl.duration,
        }),
        ...(fileSampleSubtitleUrl && {
          fileSampleSubtitleUrl: fileSampleSubtitleUrl.url,
        }),
        ...(attachmentFiles.length && { attachments }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'courses') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    const {
      coverImages,
      fileSampleUrl,
      teaserUrl,
      episodes: episodeFiles = [],
      attachments: attachmentFiles = [],
    } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'courses/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    if (fileSampleUrl && typeof fileSampleUrl === 'object') {
      if (fileSampleUrl.rawFile && !fileSampleUrl.rawFile.fileSampleUrl) {
        throw `File sample is uploading ...`
      } else {
        Object.assign(params.data, {
          fileSampleUrl: fileSampleUrl.rawFile.fileSampleUrl.url,
          fileSampleDuration: fileSampleUrl.rawFile.fileSampleUrl.duration,
        })
      }
    }

    if (teaserUrl && typeof teaserUrl === 'object') {
      if (teaserUrl.rawFile && !teaserUrl.rawFile.teaserUrl) {
        throw `Teaser is uploading ...`
      } else {
        Object.assign(params.data, {
          teaserUrl: teaserUrl.rawFile.teaserUrl.url,
          teaserDuration: teaserUrl.rawFile.teaserUrl.duration,
        })
      }
    }

    const episodeCoverImageFiles = episodeFiles.map(e => e.coverImageUrl)
    const coverImageFiles =
      coverImages && coverImages.length && coverImages.map(e => e.url)

    const [coverImageUrls, episodeCoverImageUrls] = await Promise.all([
      coverImageFiles && coverImageFiles.length
        ? Promise.all(
            coverImageFiles.map(coverImageFile => {
              if (coverImageFile && typeof coverImageFile === 'object') {
                return uploadFile({
                  file: coverImageFile,
                  folder: 'coverImage',
                  storageKey,
                })
              }
              return { url: coverImageFile }
            }),
          )
        : null,
      episodeCoverImageFiles && episodeCoverImageFiles.length
        ? Promise.all(
            episodeCoverImageFiles.map((coverImageFile, index) => {
              if (typeof coverImageFile === 'object') {
                return uploadFile({
                  file: coverImageFile,
                  folder: 'episodeCoverImage',
                  storageKey,
                  customFileName: `${index}-${Date.now().toString()}`,
                })
              }
              return { url: coverImageFile }
            }),
          )
        : null,
    ])

    const episodes = [],
      attachments = []

    for (const episodeKey in episodeFiles) {
      let episode = { ...episodeFiles[episodeKey] }
      if (typeof episode.videoUrl === 'object') {
        if (episode.videoUrl.rawFile && !episode.videoUrl.rawFile.videoUrl) {
          throw `Episode ${episode.order} is uploading ...`
        } else {
          Object.assign(episode, {
            transcodeStatus: 'New',
            videoUrl: episode.videoUrl.rawFile.videoUrl.url,
            duration: episode.videoUrl.rawFile.videoUrl.duration,
            storageKey:
              episode.storageKey || storageKey + '/episodes/' + uuidv1(),
          })
        }
      }

      Object.assign(episode, {
        coverImageUrl: episodeCoverImageUrls[episodeKey].url,
      })

      episodes.push(episode)
    }

    for (const attachmentKey in attachmentFiles) {
      const attachment = attachmentFiles[attachmentKey]
      if (typeof attachment.url === 'object') {
        if (attachment.url.rawFile && !attachment.url.rawFile.url) {
          throw 'Attachments is uploading ...'
        } else {
          attachments.push({
            ...attachment,
            url: attachment.url.rawFile.url.url,
          })
        }
      } else {
        attachments.push(attachment)
      }
    }

    const handledCoverImages =
      coverImageUrls &&
      coverImageUrls.length &&
      coverImageUrls.map((item, idx) => ({
        ...coverImages[idx],
        url: item.url,
      }))

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(attachmentFiles && attachmentFiles.length
          ? { attachments }
          : { attachments: [] }),
        ...(episodeFiles.length && { episodes }),
        ...(handledCoverImages &&
          handledCoverImages.length && {
            coverImages: handledCoverImages,
          }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'book-summaries'
  ) {
    const {
      coverImageUrl: coverImageFile,
      chapters: chapterFiles = [],
    } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'book-summaries/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const coverImageUrl = await uploadFile({
      file: coverImageFile,
      folder: 'coverImage',
      storageKey,
    })

    const chapters = []

    for (const chapterKey in chapterFiles) {
      const chapter = chapterFiles[chapterKey]
      if (typeof chapter.url === 'object') {
        if (chapter.url.rawFile && !chapter.url.rawFile.url) {
          throw 'File is uploading ...'
        } else {
          chapters.push({
            ...chapter,
            url: chapter.url.rawFile.url.url,
            duration: chapter.url.rawFile.url.duration,
          })
        }
      } else {
        chapters.push(chapter)
      }
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(chapterFiles.length && { chapters }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'ebooks') {
    const { coverImageUrl: coverImageFile, fileUrl } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'ebooks/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const [file, coverImageUrl] = await Promise.all([
      uploadFile({ file: fileUrl, folder: 'file', storageKey }),
      uploadFile({
        file: coverImageFile,
        folder: 'coverImage',
        storageKey,
      }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(file && { fileUrl: file.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'authors') {
    const { avatar: avatarFile } = params.data

    const [avatar] = await Promise.all([uploadAvatar({ file: avatarFile })])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(avatar && { avatar: avatar.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'coaches') {
    const { avatars: avatarFiles, introVideoUrl } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'coaches/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const avatars = await Promise.all(
      avatarFiles.map(avatarFile => {
        if (typeof avatarFile === 'object') {
          return uploadCoachAvatar({ file: avatarFile })
        }
        return { url: avatarFile }
      }),
    )

    if (introVideoUrl && typeof introVideoUrl === 'object') {
      if (introVideoUrl.rawFile && !introVideoUrl.rawFile.introVideoUrl) {
        throw `Intro video is uploading ...`
      } else {
        Object.assign(params.data, {
          introVideoUrl: introVideoUrl.rawFile.introVideoUrl.url,
        })
      }
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        avatars: avatars.map(a => a.url),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'users') {
    const { avatarUrl: avatarFile } = params.data

    const [avatar] = await Promise.all([
      uploadPublisherAvatar({ file: avatarFile }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(avatar && { avatarUrl: avatar.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'affiliates'
  ) {
    const { picture: pictureFile } = params.data

    const picture = await uploadPicture({ file: pictureFile })

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(picture && { picture: picture.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'slideshows'
  ) {
    const { url: imageFile } = params.data

    const [image] = await Promise.all([
      uploadSlideshowImage({ file: imageFile }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(image && { url: image.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'promotion-configs'
  ) {
    const { saleScreenInfo, bannerInfo } = params.data

    const bannerPhone = `bannerPhone/${Date.now().toString()}`
    const bannerTablet = `bannerTablet/${Date.now().toString()}`
    const imageThemeTablet = `imageThemeTablet/${Date.now().toString()}`
    const imageThemePhone = `imageThemePhone/${Date.now().toString()}`

    const [
      imageBannerPhone,
      imageBannerTablet,
      imageThemeForPhone,
      imageThemeForTablet,
    ] = await Promise.all([
      upload(bannerInfo.phone.url, bannerPhone, true),
      upload(bannerInfo.tablet.url, bannerTablet, true),
      upload(saleScreenInfo.iconTheme.phone.url, imageThemePhone, true),
      upload(saleScreenInfo.iconTheme.tablet.url, imageThemeTablet, true),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        bannerInfo: {
          ...params.data.bannerInfo,
          phone: {
            ...params.data.bannerInfo.phone,
            url: imageBannerPhone ? imageBannerPhone.url : bannerInfo.phone.url,
          },
          tablet: {
            ...params.data.bannerInfo.tablet,
            url: imageBannerTablet
              ? imageBannerTablet.url
              : bannerInfo.tablet.url,
          },
        },
        saleScreenInfo: {
          ...params.data.saleScreenInfo,
          iconTheme: {
            phone: {
              ...params.data.saleScreenInfo.iconTheme.phone,
              url: imageThemeForPhone
                ? imageThemeForPhone.url
                : saleScreenInfo.iconTheme.phone.url,
            },
            tablet: {
              ...params.data.saleScreenInfo.iconTheme.tablet,
              url: imageThemeForTablet
                ? imageThemeForTablet.url
                : saleScreenInfo.iconTheme.tablet.url,
            },
          },
        },
      },
    }

    return requestHandler(type, resource, newParams)
  }
  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'campaigns'
  ) {
    const { bannerInfo } = params.data

    const bgImagePhone = `bgImagePhone/${Date.now().toString()}`
    const bgImageTablet = `bgImageTablet/${Date.now().toString()}`
    const textLayerPhone = `textLayerPhone/${Date.now().toString()}`
    const textLayerTablet = `textLayerTablet/${Date.now().toString()}`

    const [
      bgImagePhoneUploadedObj,
      bgImageTabletUploadedObj,
      textLayerPhoneUploadedObj,
      textLayerTabletUploadedObj,
    ] = await Promise.all([
      upload(bannerInfo.bgImagePhone.url, bgImagePhone, true),
      upload(bannerInfo.bgImageTablet.url, bgImageTablet, true),
      upload(bannerInfo.textLayerPhone.url, textLayerPhone, true),
      upload(bannerInfo.textLayerTablet.url, textLayerTablet, true),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        bannerInfo: {
          ...params.data.bannerInfo,
          bgImagePhone: {
            ...params.data.bannerInfo.bgImagePhone,
            url: bgImagePhoneUploadedObj
              ? bgImagePhoneUploadedObj.url
              : bannerInfo.bgImagePhone.url,
          },
          bgImageTablet: {
            ...params.data.bannerInfo.bgImageTablet,
            url: bgImageTabletUploadedObj
              ? bgImageTabletUploadedObj.url
              : bannerInfo.bgImageTablet.url,
          },
          textLayerPhone: {
            ...params.data.bannerInfo.textLayerPhone,
            url: textLayerPhoneUploadedObj
              ? textLayerPhoneUploadedObj.url
              : bannerInfo.textLayerPhone.url,
          },
          textLayerTablet: {
            ...params.data.bannerInfo.textLayerTablet,
            url: textLayerTabletUploadedObj
              ? textLayerTabletUploadedObj.url
              : bannerInfo.textLayerTablet.url,
          },
        },
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'collections'
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { coverImageUrl } = params.data

    const coverImage = await uploadFile({
      file: coverImageUrl,
      folder: 'coverImage',
      storageKey,
    })

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImage && { coverImageUrl: coverImage.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    [
      'musics',
      'sleep-stories',
      'news-feeds',
      'articles',
      'kids-stories',
    ].includes(resource)
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { fileUrl, coverImageUrl } = params.data

    const [file, coverImage] = await Promise.all([
      uploadFile({ file: fileUrl, folder: 'file', storageKey }),
      uploadFile({ file: coverImageUrl, folder: 'coverImage', storageKey }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(file && { fileUrl: file.url, duration: file.duration }),
        ...(coverImage && { coverImageUrl: coverImage.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'meditations'
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { maleVoiceUrl, femaleVoiceUrl, coverImageUrl } = params.data

    const coverImage = await uploadFile({
      file: coverImageUrl,
      folder: 'coverImage',
      storageKey,
    })
    let maleVoice = null
    let femaleVoice = null

    if (maleVoiceUrl) {
      maleVoice = await uploadFile({
        file: maleVoiceUrl,
        key: `${storageKey}/file/${Date.now().toString()}`,
      })
    }

    if (femaleVoiceUrl) {
      femaleVoice = await uploadFile({
        file: femaleVoiceUrl,
        key: `${storageKey}/file/${(Date.now() + 1000).toString()}`,
      })
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImage && { coverImageUrl: coverImage.url }),
        ...(maleVoice && {
          maleVoiceUrl: maleVoice.url,
          maleVoiceDuration: maleVoice.duration,
        }),
        ...(femaleVoice && {
          femaleVoiceUrl: femaleVoice.url,
          femaleVoiceDuration: femaleVoice.duration,
        }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'audio-series'
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { coverImageUrl, headerImageUrl } = params.data

    const [coverImage, headerImage] = await Promise.all([
      uploadFile({ file: coverImageUrl, folder: 'coverImage', storageKey }),
      uploadFile({ file: headerImageUrl, folder: 'headerImage', storageKey }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImage && { coverImageUrl: coverImage.url }),
        ...(headerImage && { headerImageUrl: headerImage.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'guided-meditations'
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { coverImageUrl, sessions = [] } = params.data
    console.log(
      '🚀 ~ file: uploadFileCapable.js ~ line 559 ~ addUploadFeature ~ sessions',
      sessions,
    )

    const guidedMeditationSessions = []

    for (const sessionKey in sessions) {
      const session = sessions[sessionKey]
      let maleVoiceUrl = session.maleVoiceUrl
      let femaleVoiceUrl = session.femaleVoiceUrl
      let maleVoiceDuration = session.maleVoiceDuration
      let femaleVoiceDuration = session.femaleVoiceDuration

      if (typeof session.maleVoiceUrl === 'object' && session.maleVoiceUrl) {
        if (!session.maleVoiceUrl.rawFile.maleVoiceUrl) {
          throw 'File is uploading ...'
        } else {
          maleVoiceUrl = session.maleVoiceUrl.rawFile.maleVoiceUrl.url
          maleVoiceDuration = session.maleVoiceUrl.rawFile.maleVoiceUrl.duration
        }
      }

      if (
        typeof session.femaleVoiceUrl === 'object' &&
        session.femaleVoiceUrl
      ) {
        if (!session.femaleVoiceUrl.rawFile.femaleVoiceUrl) {
          throw 'File is uploading ...'
        } else {
          femaleVoiceUrl = session.femaleVoiceUrl.rawFile.femaleVoiceUrl.url
          femaleVoiceDuration =
            session.femaleVoiceUrl.rawFile.femaleVoiceUrl.duration
        }
      }

      guidedMeditationSessions.push({
        ...session,
        maleVoiceUrl,
        maleVoiceDuration,
        femaleVoiceUrl,
        femaleVoiceDuration,
      })
    }

    const coverImage = await uploadFile({
      file: coverImageUrl,
      folder: 'coverImage',
      storageKey,
    })

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImage && { coverImageUrl: coverImage.url }),
        ...(guidedMeditationSessions.length && {
          sessions: guidedMeditationSessions,
        }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'meditation-backgrounds'
  ) {
    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = `${resource}/${uuidv1()}`
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const { fileUrl } = params.data

    const file = await uploadFile({
      file: fileUrl,
      folder: 'file',
      storageKey,
    })

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(file && { fileUrl: file.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'collection-codes'
  ) {
    const { bannerImageUrl, bannerBackgroundUrl } = params.data
    let { quotes } = params.data

    const [bannerImage, bannerBackground] = await Promise.all([
      uploadFile({
        file: bannerImageUrl,
        folder: 'bannerImage',
        storageKey: resource,
      }),
      uploadFile({
        file: bannerBackgroundUrl,
        folder: 'bannerBackground',
        storageKey: resource,
      }),
    ])

    if (quotes && quotes.length) {
      for (let i = 0; i < quotes.length; i++) {
        const quote = quotes[i]
        if (quote && quote.avatar && typeof quote.avatar === 'object') {
          const uploadedImage = await uploadFile({
            file: quote.avatar,
            folder: 'quoteAvatar',
            storageKey: resource,
          })
          quotes[i] = {
            ...quote,
            avatar: uploadedImage.url,
          }
        }
      }
    }

    const newParams = {
      ...params,
      data: {
        ...params.data,
        quotes: quotes,
        ...(bannerImage && { bannerImageUrl: bannerImage.url }),
        ...(bannerBackground && {
          bannerBackgroundUrl: bannerBackground.url,
        }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    (resource === 'category-groups' || resource === 'categories')
  ) {
    const { icon, ...rest } = params.data

    let uploadIcon = null
    if (icon && typeof icon === 'object') {
      uploadIcon = await convertFileToBase64(icon)
    }

    const newParams = {
      ...params,
      data: {
        ...rest,
        ...(uploadIcon && { icon: uploadIcon }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'bundles') {
    const { imageUrl: imageFile } = params.data

    const imageUrl = await uploadFile({
      file: imageFile,
      storageKey: 'bundles',
    })

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(imageUrl && { imageUrl: imageUrl.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'podcasters'
  ) {
    const { coverImageUrl: coverImageFile, fileUrl } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'podcasters/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const [file, coverImageUrl] = await Promise.all([
      uploadFile({ file: fileUrl, folder: 'file', storageKey }),
      uploadFile({
        file: coverImageFile,
        folder: 'coverImage',
        storageKey,
      }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(file && { fileUrl: file.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (['CREATE', 'PATCH', 'UPDATE'].includes(type) && resource === 'channels') {
    const { coverImageUrl: coverImageFile, fileUrl } = params.data

    let storageKey = ''
    if (type === 'CREATE') {
      storageKey = params.data.storageKey = 'channels/' + uuidv1()
    } else {
      storageKey =
        params.previousData && params.previousData.storageKey
          ? params.previousData.storageKey
          : ''
    }

    const [file, coverImageUrl] = await Promise.all([
      uploadFile({ file: fileUrl, folder: 'file', storageKey }),
      uploadFile({
        file: coverImageFile,
        folder: 'coverImage',
        storageKey,
      }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(coverImageUrl && { coverImageUrl: coverImageUrl.url }),
        ...(file && { fileUrl: file.url }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'landing-page'
  ) {
    const { audiobookImage, courseImage } = params.data

    const [audiobookImageUpload, courseImageUpload] = await Promise.all([
      uploadFile({
        file: audiobookImage,
        folder: 'landingPage',
        storageKey: resource,
      }),
      uploadFile({
        file: courseImage,
        folder: 'landingPage',
        storageKey: resource,
      }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(audiobookImageUpload && {
          audiobookImage: audiobookImageUpload.url,
        }),
        ...(courseImageUpload && {
          courseImage: courseImageUpload.url,
        }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  if (
    ['CREATE', 'PATCH', 'UPDATE'].includes(type) &&
    resource === 'b2b-organizations'
  ) {
    const { logo } = params.data

    const [logoUpload] = await Promise.all([
      uploadFile({
        file: logo,
        folder: 'b2bOrganization',
        storageKey: 'avatar',
      }),
    ])

    const newParams = {
      ...params,
      data: {
        ...params.data,
        ...(logoUpload && {
          logo: logoUpload.url,
        }),
      },
    }

    return requestHandler(type, resource, newParams)
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

export default addUploadFeature
