import React from 'react'
import { Edit } from 'react-admin'
import B2BOrganizationForm from './B2BOrganizationForm'

const B2BOrganizationEdit = props => (
  <Edit undoable={false} {...props}>
    <B2BOrganizationForm editing />
  </Edit>
)

export default B2BOrganizationEdit
