import React from 'react'
import { Create } from 'react-admin'
import B2BOrganizationForm from './B2BOrganizationForm'

const B2BOrganizationCreate = props => (
  <Create {...props}>
    <B2BOrganizationForm />
  </Create>
)

export default B2BOrganizationCreate
